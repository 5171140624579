import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  hero,
  image,
  productOverview,
  partners,
  highlightsHero,
  highlightWithImage,
  productsWithLinks,
  footer,
} from "../../../data/appetize/enterprise-retail";
import { enterpriseSupport } from "../../../data/spoton-contacts";

const SimpleHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/SimpleHero")
);
const LargeImage = loadable(() =>
  import("../../../components-v2/Sections/LargeImage")
);
const ProductOverview = loadable(() =>
  import("../../../components-v2/Sections/ProductOverview")
);
const HighlightsHeroExtended = loadable(() =>
  import("../../../components-v2/Sections/Hero/HighlightsHeroExtended")
);
const PartnerLogosAppetize = loadable(() =>
  import("../../../components-v2/Sections/PartnerLogos/partner-logos-appetize")
);
const ProductsWithLinksPlainBackground = loadable(() =>
  import("../../../components-v2/Sections/ProductsWithLinksPlainBackground")
);
const FooterHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/FooterHero")
);
const HighlightWithImageAndOffsetText = loadable(() =>
  import("../../../components-v2/Sections/HighlightWithImageAndOffsetText")
);

const EnterpriseRetail = () => {
  return (
    <Layout className="non-fixed-width a-ec" {...enterpriseSupport}>
      <SimpleHero className="mt-32 lg:mt-40" {...hero} />
      <SEO
        loadChat={false}
        title="Enterprise Retail POS | SpotOn"
        description={hero.title}
        cannonical="https://spoton.com/enterprise/enterprise-retail/"
      />
      <LargeImage className="mt-16 lg:mt-24" {...image} />

      <ProductOverview
        className="mt-20 lg:mt-36"
        {...productOverview}
        offsetText
        rightBackground
      />

      {/* <HighlightWithImageAndOffsetText */}
      {/*  className="mt-20 lg:mt-48" */}
      {/*  {...highlightWithImage} */}
      {/* /> */}

      <PartnerLogosAppetize className="mt-20 lg:mt-36" {...partners} />

      <HighlightsHeroExtended
        className="mt-20 lg:mt-36"
        titleSymbol="?"
        {...highlightsHero}
      />

      <ProductsWithLinksPlainBackground
        className="mt-20 lg:mt-36"
        {...productsWithLinks}
      />

      <FooterHero className="my-20 lg:my-36" {...footer} />
    </Layout>
  );
};

export default EnterpriseRetail;
