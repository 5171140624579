export const hero = {
  title: "The retail POS solution redefining how you operate",
  subTitle: "Enterprise Retail",
  subtext:
    "Increase sales and improve efficiency with a cloud-based omnichannel commerce solution built for enterprise businesses.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/enterprise-retail/demo",
  },
};

export const image = {
  imageName: "enterprise-retail-hero.png",
  mobileImageName: "enterprise-retail-hero-mobile.png",
  title: "ENTERPRISE RETAIL",
};

export const productOverview = {
  title: "Simplify your retail operations",
  subtext:
    "Smart retail POS software that centralizes your merchandise shop and back office management in one place.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/enterprise-retail/demo",
  },
  subSections: [
    {
      title: "Intuitive retail workflows",
      subtext:
        "Move guests quickly through checkout lines with the ability to scan barcoded items, browse images, or search by name or SKU. Simplify discounts and promotions by enabling discounts at the item and cart level with pre-built promo templates. Track inventory levels in real-time with front-of-house countdowns that ensure staff know how many items are available to sell.",
      imageName: "enterprise-retail-product-1.png",
    },
    {
      title: "Streamline returns, refunds, and exchanges",
      subtext:
        "Handle returns, exchanges, discounts, and new purchases in a single, streamlined transaction. Set flags for non-returnable items, limitations based on date or item, and receipted and non-receipted options, plus view audit trails showing the date, time, location, or cashier history of returned items.",
      imageName: "enterprise-retail-product-2.png",
    },
  ],
};

export const highlightWithImage = {
  title: "Streamline returns, refunds, and exchanges",
  subtext:
    "Handle returns, exchanges, discounts, and new purchases in a single, streamlined transaction. Set flags for non-returnable items, limitations based on date or item, and receipted and non-receipted options, plus view audit trails showing the date, time, location, or cashier history of returned items.",
  imageName: "enterprise-retail-product-2.png",
};

export const partners = {
  title: "Integrations with the largest network of partners",
  desktopImageName: "appetize-enterprise-retail-partners-desktop.png",
  mobileImageName: "appetize-enterprise-retail-partners-mobile.png",
};

export const highlightsHero = {
  image: {
    imageName: "enterprise-retail-highlights-2.png",
    mobileImageName: "enterprise-retail-highlights-2-mobile.png",
    title: "Combine payments with our best-in-class cloud software",
    subTitle:
      "Designed for multi-unit hospitality and venue businesses, SpotOn’s enterprise payment processing services lets you package ordering software and payments to reduce costs and increase transparency and efficiency.",
    subtext: "ENTERPRISE SOLUTIONS",
  },
  title: "Why switch to SpotOn payments",
  subtext:
    "SpotOn brings new payment solutions and cost savings to larger venues and hospitality brands. Increasingly, enterprise businesses with multiple location and POS terminals are looking to conveniently unify their tech and payments stack. SpotOn solves for this by eliminating the need for expensive third-party services, and provides a secure and streamlined platform with a single point of support.",
  highlights: [
    {
      title: "Better rates \n guarantee",
      subtext:
        "Eliminate the need to work with multiple parties and the high cost of third-party fees.",
    },
    {
      title: "Transparent pricing \n and reporting",
      subtext:
        "Single report includes merchant services pricing, fees, and key business insights to increase transparency.",
    },
    {
      title: "Single point \n of support",
      subtext:
        "One point of contact for inquiries across payments, POS systems, and back-of-house software, gateway, and processor vendors.",
    },
    {
      title: "Future-proof \n solution",
      subtext:
        "Supports dip, tap, scan, RFID, and NFC functionality, digital currencies/wallets, and loaded value and gift card platforms.",
    },
  ],
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/enterprise-retail/demo",
  },
};

export const productsWithLinks = {
  title: "A true omnichannel retail POS product suite",
  subtext: "Manage your entire operation from one centralized platform.",
  products: [
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Point-of-Sale",
      description:
        "Reduce wait times with a user-friendly POS solution that processes orders and payments fast, even when offline.",
      linkTo: "/enterprise/pos-terminals",
      imageName: "products-kiosk.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform your customer experience, provide valuable business insights, and deliver ROI to your retail business.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/enterprise-retail/demo",
  },
};
